<template>
    <div id="apps">
        <div class="jrhx clear">
            <div class="top clear">
                <div class="legt1"><h5>昨日核销</h5>
                    <p>{{info.yesterdayCount}}</p></div>
                <div class="cent"><h4>今日核销</h4>
                    <p>{{info.todayCount}}</p></div>
                <div class="rigth"><h5>累计核销</h5>
                    <p>{{info.count}}</p></div>
                <router-link to="/xhjilv">
                    <button>核销记录</button>
                </router-link>
            </div>
            <div class="boc clear">
                <ul class="clear">
                    <li @click="scan">扫码核销</li>
                    <li @click="doCode">手动核销</li>
                    <router-link to="/changepass">
                        <li>修改密码</li>
                    </router-link>
                    <li @click="logout">退出登录</li>
                </ul>
            </div>
        </div>

        <van-dialog v-model="showCode" title="手动核销" show-cancel-button @confirm="submit">
            <van-form>
                <van-field
                        v-model="code"
                        label="核销码"
                        placeholder="请输入核销码"
                        :rules="[{ required: true, message: '请输入核销码' }]"
                />
            </van-form>
        </van-dialog>

    </div>
</template>

<script>
  import {getWxConfig} from "@/api/wx";
  import {hexiaoInfo, userCode} from "@/api/order";
  import {Dialog, Toast} from "vant";

  export default {
    name: 'app',
    components: {},
    data () {
      return {
        info: {},
        showCode: false,
        code: ''
      }
    },
    methods: {
      logout() {
        Dialog.confirm({
          title: '提示',
          message: ' 确认要退出当前用户吗？',
        })
          .then(() => {
            this.$store.dispatch('Logout').then(() => {
              this.$router.push('/login')
            })
          });
      },
      submit() {
        if (this.code.length <= 0) {
          Toast('请输入核销码');
          return;
        }
        this.hexiao(1)
      },
      doCode() {
        this.showCode = true
      },
      loadInfo() {
        hexiaoInfo().then(data =>  {
          if (data.success == true) {
            this.info = data.data
          }
        })
      },
      async loadWxConfig () {
        const url = window.location.href;
        const res = await getWxConfig(url);
        if (res.success == true) {
          wx.config({
            debug: false,
            appId: res.data.appId,
            timestamp: res.data.timestamp,
            nonceStr: res.data.nonceStr,
            signature: res.data.signature,
            jsApiList: [
              'scanQRCode',
            ]
          });
        }
      },
      scan() {
        const _this = this;
        wx.ready(function(){
          wx.scanQRCode({
            needResult: 1,
            scanType: ["qrCode"],
            success: (res) => {
              var result = res.resultStr;
              if (result.length > 0) {
                _this.code = result;
                _this.hexiao(0)
              }
            },
          });
        });
      },
      hexiao(type) {
        const toast = Toast.loading({
          duration: 0,
          forbidClick: true,
          message: '加载中'
        });
        userCode({
          code: this.code,
          type
        }).then(data => {
          if (data.success == true) {
            this.loadInfo();
            Toast('商品核销成功')
          } else {
            Toast(data.msg)
          }
        }).catch(() => {
          toast.clear()
        })
      }
    },
    created() {
      this.loadWxConfig();
      this.loadInfo();
    }
  }
</script>

<style>
    .clear {
        clear: both;
        overflow: hidden;
    }

    * {
        margin: 0;
        padding: 0;
    }

    .jrhx {
        width: 100%;
    }

    .jrhx .top {
        width: 100%;
        background: #EE0000;
        color: #fff;
        display: -webkit-flex; /* Safari */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 4%;
        text-align: center;
        padding-bottom: 5%;
        position: relative;
    }

    .jrhx .top h5, .jrhx .top h4 {
        font-family: 方正准圆-标准;
        font-weight: 500;
        font-size: 14px;
    }

    .jrhx .top > div {
        width: calc(100% / 3);
    }

    .jrhx .top p {
        margin-top: 3%;
        font-size: 12px;
        text-align: center;
        display: block;
        font-family: 方正准圆-标准;
    }

    .jrhx .top .legt1 {
        margin-top: 12%;
    }

    .jrhx .top .rigth {
        margin-top: 12%;
    }

    .jrhx .top h5 {
        font-size: 13px;
    }

    .jrhx .top button {
        position: absolute;
        border-radius: 4px;
        color: rgba(16, 16, 16, 100);
        font-size: 12px;
        display: block;
        padding: 1% 5%;
        background: #fff;
        text-align: center;
        font-family: 'PingFang SC';
        bottom: 0;
        left: 50%;
        margin-left: -41px;
        border: 1px solid rgba(187, 187, 187, 100);
    }

    .jrhx .boc {
        width: 100%;
    }

    .jrhx .boc ul li {
        width: 43%;
        margin: 5% 0 0 4%;
        border-radius: 4px;
        color: rgba(16, 16, 16, 100);
        font-size: 13px;
        text-align: center;
        font-family: 'PingFang SC';
        border: 1px solid rgba(187, 187, 187, 100);
        padding: 8% 0;
        float: left;
        list-style-type: none;
    }

    .jrhx .boc .butyy {
        border-radius: 4px;
        color: rgba(16, 16, 16, 100);
        width: 50%;
        margin: 5% auto;
        font-size: 14px;
        border: 1px solid rgba(187, 187, 187, 100);
    }

    .jrhx .boc .butyy p {
        color: rgba(16, 16, 16, 100);
        font-size: 13px;
        display: block;
        text-align: center;
        font-family: '方正准圆-标准';
        padding: 12% 0 20% 0;
    }

    .jrhx .boc .butyy button {
        border-radius: 4px;
        color: rgba(16, 16, 16, 100);
        font-size: 12px;
        display: block;
        margin: 0 auto 6% auto;
        text-align: center;
        font-family: 'PingFang SC';
        padding: 1% 12%;
        background: #fff;
        border: 1px solid rgba(187, 187, 187, 100);
    }
</style>
